import React from 'react';
import { Component, useState  } from 'react';


// Start Openlayers imports
import { Map, View} from 'ol'
import { GeoJSON } from 'ol/format'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource, OSM as OSMSource, TileWMS as TileWMSSource } from 'ol/source'
import { Select as SelectInteraction, defaults as DefaultInteractions } from 'ol/interaction'
import { Attribution, ScaleLine, ZoomSlider, Zoom, Rotate, MousePosition, OverviewMap, defaults as DefaultControls} from 'ol/control'
import {Style,Fill as FillStyle, RegularShape as RegularShapeStyle, Stroke as StrokeStyle} from 'ol/style'
import { Projection,get as getProjection} from 'ol/proj'

import Overlay from 'ol/Overlay.js';
import XYZ from 'ol/source/XYZ.js';
import {toLonLat} from 'ol/proj.js';
import {toStringHDMS} from 'ol/coordinate.js';

// interface myLayerObject {

//     // source: any;
//     // visible: boolean;

// }


// const layerArray:  myLayerObject[] = [];

const URLArray = [];


// DEVELOPMENT
// const APP_DOMAIN = "http://localhost:3000";
// const GEOSERVER_BASE = "http://localhost:8080/geoserver";
// const OSM = "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png";
// const ICON_IMG = "http://localhost/img";

// const SEMPADAN_PAHANG = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Apahang_boundary&outputFormat=application%2Fjson";
// const JALAN_PERSEKUTUAN = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Afederal_road&outputFormat=application%2Fjson";
// const JALAN_NEGERI_PAHANG = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Astate_road_line&outputFormat=application%2Fjson"
// const SEMPADAN_MUKIM = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Amukim_boundary&outputFormat=application%2Fjson";
// const KAMPUNG = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Akampung&outputFormat=application%2Fjson"; 
// const JKR_KOORDINAT = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_koordinat&outputFormat=application%2Fjson";
// const JKR_JALAN = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_roadline&outputFormat=application%2Fjson";
// const JPS_JALAN = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJPS_roadline&outputFormat=application%2Fjson";

// STAGING
// const APP_DOMAIN = "http://localhost:3000";
// const GEOSERVER_BASE = "http://localhost:8181/geoserver"; 
// const OSM = "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png";
// const ICON_IMG = "https://pahangroad.szyconsultants.com/img";

// const SEMPADAN_PAHANG = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Apahang_boundary&outputFormat=application%2Fjson";
// const JALAN_PERSEKUTUAN = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Afederal_road&outputFormat=application%2Fjson";
// const JALAN_NEGERI_PAHANG = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Astate_road_line&outputFormat=application%2Fjson"
// const SEMPADAN_MUKIM = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Amukim_boundary&outputFormat=application%2Fjson";
// const KAMPUNG = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Akampung&outputFormat=application%2Fjson"; 
// const JKR_KOORDINAT = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_koordinat&outputFormat=application%2Fjson";
// const JKR_JALAN = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_roadline&outputFormat=application%2Fjson";
// const JPS_JALAN = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJPS_roadline&outputFormat=application%2Fjson";


// PRODUCTION
// const APP_DOMAIN = "https://pahangroad.szyconsultants.com/";
const APP_DOMAIN = "https://szywebmapservice.com/";
const GEOSERVER_BASE = "https://szywebmapservice.com/geoserver"; 
const OSM = "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const ICON_IMG = "https://pahangroad.szyconsultants.com/img";

const SEMPADAN_PAHANG = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Apahang_boundary&outputFormat=application%2Fjson";
const JALAN_PERSEKUTUAN = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Afederal_road&outputFormat=application%2Fjson";
const JALAN_NEGERI_PAHANG = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Astate_road_line&outputFormat=application%2Fjson"
const SEMPADAN_MUKIM = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Amukim_boundary&outputFormat=application%2Fjson";
const KAMPUNG = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Akampung&outputFormat=application%2Fjson"; 
const JKR_KOORDINAT = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_koordinat&outputFormat=application%2Fjson";
const JKR_JALAN = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_roadline&outputFormat=application%2Fjson";
const JPS_JALAN = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJPS_roadline&outputFormat=application%2Fjson";

URLArray.push(APP_DOMAIN, GEOSERVER_BASE, OSM, JALAN_PERSEKUTUAN, JALAN_NEGERI_PAHANG, SEMPADAN_MUKIM, KAMPUNG, JKR_KOORDINAT, JKR_JALAN, JPS_JALAN, ICON_IMG);
export const configURL: any[] = URLArray;


  export function geConfigURL(): any[] {
 
    return configURL;
    

}