import React from 'react';
import { Component, useState, useEffect  } from 'react';
import 'ol/ol.css';
import './MapComponent.css';
import '../Layer/LegendLayer.css';

// Start Openlayers imports
import { Map, View, Feature} from 'ol'
import { GeoJSON } from 'ol/format'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource, OSM as OSMSource, TileWMS as TileWMSSource } from 'ol/source'
import { Select as SelectInteraction, defaults as DefaultInteractions } from 'ol/interaction'
import { Attribution, ScaleLine, ZoomSlider, Zoom, Rotate, MousePosition, OverviewMap, defaults as DefaultControls} from 'ol/control'
import {Style,Text, Stroke, Fill as FillStyle, RegularShape as RegularShapeStyle, Stroke as StrokeStyle} from 'ol/style'
import { Projection,get as getProjection} from 'ol/proj'

import Overlay from 'ol/Overlay.js';
import XYZ from 'ol/source/XYZ.js';
import {toLonLat} from 'ol/proj.js';
import {toStringHDMS} from 'ol/coordinate.js';
// import LegendLayer from '../Layer/LegendLayer';
import { FALSE } from 'ol/functions';
// import VectorTileLayer from 'ol/layer/VectorTile.js';
// import VectorTileSource from 'ol/source/VectorTile.js';
// import LayerSwitcher from 'ol-layerswitcher';
// import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';

import Select from 'ol/interaction/Select';
import { click } from 'ol/events/condition';
import { singleClick } from 'ol/events/condition';
import { click as clickInteraction } from 'ol/events/condition';

import {Layers} from '../Layer/Layer'
import {configURL} from '../config/config'

import {Fragment} from 'react'
import {Layers as LayerIcon} from '@styled-icons/material'
import {Binoculars} from '@styled-icons/boxicons-solid/Binoculars'
import {SearchOutline} from '@styled-icons/evaicons-outline/SearchOutline';
import {ManageSearch} from '@styled-icons/material-outlined/ManageSearch'
import {Book} from '@styled-icons/icomoon/Book';
import {Search} from '@styled-icons/boxicons-solid/Search';
import {RoadMap} from '@styled-icons/remix-line/RoadMap'
import {CloseCircle} from '@styled-icons/evaicons-solid/CloseCircle';
import { useLocation } from 'react-router-dom';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import AdvanceSearch from '../Search/AdvanceSearch';
import Form from 'react-bootstrap/Form';
import Pdf from '../Documents/Doc1.pdf';
import Point from 'ol/geom/Point.js';
import {Icon} from 'ol/style.js';
import { Button, Container, Col, Row, Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import { BackgroundColor } from 'styled-icons/foundation';


interface Item {
    id: number;
    name: string;
  }


const MapComponent: React.FC = () => {


    const [isJlnPersekutuanChecked, setJlnPersekutuanChecked] = useState(false);

    const [isJlnPahangChecked, setJlnPahangChecked] = useState(false);
    
    const [isSempMukimChecked, setSempMukimChecked] = useState(false);

    const [isKampungChecked, setKampungChecked] = useState(false);
    
    const [isKoordinatJKRChecked, setKoordinatJKRChecked] = useState(false);
    
    const [isJlnJKRChecked, setJlnJKRChecked] = useState(false);
    
    const [isJlnJPSChecked, setJlnJPSChecked] = useState(false);

    const [isOSMChecked, setOSMChecked] = useState(true);

    const [isImageryChecked, setImageryChecked] = useState(false);

    
    

  function closeAttributeInfo() {

    const attributeInfoDiv = document.getElementById('formatted-json') as HTMLElement
    attributeInfoDiv.style.display = 'none';

    const attributeInfoCloseButton = document.getElementById('attributeInfoCloseButton') as HTMLElement
    attributeInfoCloseButton.style.display = 'none';

   }

    useEffect(() => {

      

   
        const mapInit = new Map({
            //  Display the map in the div with the id of map
            target: 'mapDiv',
            layers: [Layers[0], Layers[1], Layers[2], Layers[3], Layers[4], Layers[5], Layers[6], Layers[7]],
            // overlays: [overlay],
            // Add in the following map controls
            controls: DefaultControls().extend([
                // new ZoomSlider(),
                new MousePosition(),
                new ScaleLine(),
                new OverviewMap()
            ]),
            // Render the tile layers in a map view with a Mercator projection
            view: new View({
                projection: 'EPSG:4326',
                center: [102.509771,3.901093],
                zoom: 9,
                minZoom:9,
              //   extent: [west, south, east, north]
                extent:[101.33158999987864,2.459989999938614,104.22334000034078,4.781479984779905]
              //   extent:[101.667480,3.305050,103.471985,4.562736]
            })
        })
  
        const params = new URLSearchParams(window.location.search);
        console.log(params.get('x'));

        if (params.get('x') != null) {

          var coord = [parseFloat(params.get('x')), parseFloat(params.get('y'))];

          mapInit.setView(
            new View({
            projection: 'EPSG:4326',  
            center: coord,
            zoom: 17,
            extent:[101.33158999987864,2.459989999938614,104.22334000034078,4.781479984779905]
        }));

        const iconFeature = new Feature({
          geometry: new Point([parseFloat(params.get('x')), parseFloat(params.get('y'))]),
        });
        
        const iconStyle = new Style({
          image: new Icon({
            anchor: [0.5, 0.5],
            scale: 0.8,
            offset: [0,0],
            src: 'https://pahangroad.szyconsultants.com/img/round_marker.png',
          }),
          text: new Text({
            text: params.get('nj'),
            font: 'bold 12px Calibri,sans-serif',
            fill: new FillStyle({
              color: 'black',
            }),
            stroke: new Stroke({
              color: 'white',
              width: 1,
            }),
          }),
        });
        
        iconFeature.setStyle(iconStyle);

        const PtJalanvectorSource = new VectorSource({
          features: [iconFeature],
        });
        
        const PtJalanvectorLayer = new VectorLayer({
          source: PtJalanvectorSource,
        });

        mapInit.getLayers().extend([PtJalanvectorLayer]);

        }
  
  
        const select = new Select({
          condition: clickInteraction,
          layers: [Layers[1], Layers[2], Layers[3], Layers[4], Layers[5], Layers[6], Layers[7]],
        });



        const attributeInfoDiv = document.getElementById('formatted-json') as HTMLElement

        attributeInfoDiv.style.display = 'none';

        const attributeInfoCloseButton = document.getElementById('attributeInfoCloseButton') as HTMLElement


      
        select.on('select', (event) => {
          if (event.selected.length > 0) {

            attributeInfoDiv.style.display = 'block';
            attributeInfoCloseButton.style.display = 'block'
            // Access attributes of the selected features
            const selectedFeature = event.selected[0];
            const attributes = selectedFeature.getProperties();
            console.log('Selected feature attributes:', attributes);
            
            delete attributes.geometry;

            // const jsonStringify = JSON.stringify(attributes);

            // const JsonObject = JSON.parse(jsonStringify);

            // const formattedJsonString = JSON.stringify(JsonObject, null, 2);
            const formattedJsonString = JSON.stringify(attributes, null, 2);
            // document.getElementById('formatted-json').innerHTML = "<label id='closeAttributeInfo' style='text-align:right;color:red;position:absolute;left:92%;'>x</label>";
            document.getElementById('formatted-json').innerHTML = formattedJsonString;
            
            // setAttributesInfo(attributes);
            // const attData = JSON.parse(JSON.stringify(attributes));
            // const attArray = JSON.parse(JSON.stringify(attributes));
            // console.log(attArray);

            // console.log(attArray.RD_NAME);
            // setAttributesInfo(attArray);
           
        
              



          }
        });
      
        mapInit.addInteraction(select);  



       
    const handleCheckboxJlnPersekutuan = (event: Event) => {
      const checkbox = event.target as HTMLInputElement;
      // Add your condition here
      if (checkbox.checked) {
        console.log('Checkbox is checked');
        Layers[1].setVisible(true);
        // Do something when the checkbox is checked
      } else {
        console.log('Checkbox is unchecked');
        Layers[1].setVisible(false);
        // Do something when the checkbox is unchecked
      }

  }

  // Attach the event listener to the checkbox
const JlnPersekutuanCB = document.getElementById('JlnPersekutuanCB') as HTMLInputElement;
JlnPersekutuanCB.addEventListener('change', handleCheckboxJlnPersekutuan);


const handleCheckboxJlnPahang = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  // Add your condition here
  if (checkbox.checked) {
    console.log('Checkbox is checked');
    Layers[2].setVisible(true);
    // Do something when the checkbox is checked
  } else {
    console.log('Checkbox is unchecked');
    Layers[2].setVisible(false);
    // Do something when the checkbox is unchecked
  }

}

// Attach the event listener to the checkbox
const JlnPahangCB = document.getElementById('JlnPahang_CB') as HTMLInputElement;
JlnPahangCB.addEventListener('change', handleCheckboxJlnPahang);


const handleCheckboxSempMukim = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  // Add your condition here
  if (checkbox.checked) {
    console.log('Checkbox is checked');
    Layers[3].setVisible(true);
    // Do something when the checkbox is checked
  } else {
    console.log('Checkbox is unchecked');
    Layers[3].setVisible(false);
    // Do something when the checkbox is unchecked
  }

}

// Attach the event listener to the checkbox
const sempMukimCB = document.getElementById('SempMukim_CB') as HTMLInputElement;
sempMukimCB.addEventListener('change', handleCheckboxSempMukim);

const handleCheckboxKampung = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  // Add your condition here
  if (checkbox.checked) {
    console.log('Checkbox is checked');
    Layers[4].setVisible(true);
    // Do something when the checkbox is checked
  } else {
    console.log('Checkbox is unchecked');
    Layers[4].setVisible(false);
    // Do something when the checkbox is unchecked
  }

}

// Attach the event listener to the checkbox
const kampungCB = document.getElementById('Kampung_CB') as HTMLInputElement;
kampungCB.addEventListener('change', handleCheckboxKampung);

const handleCheckboxKoordinatJKR = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  // Add your condition here
  if (checkbox.checked) {
    console.log('Checkbox is checked');
    Layers[5].setVisible(true);
    // Do something when the checkbox is checked
  } else {
    console.log('Checkbox is unchecked');
    Layers[5].setVisible(false);
    // Do something when the checkbox is unchecked
  }

}

// Attach the event listener to the checkbox
const koordinatJKR_CB = document.getElementById('KoordinatJKR_CB') as HTMLInputElement;
koordinatJKR_CB.addEventListener('change', handleCheckboxKoordinatJKR);


const handleCheckboxJlnJKR = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  // Add your condition here
  if (checkbox.checked) {
    console.log('Checkbox is checked');
    Layers[6].setVisible(true);
    // Do something when the checkbox is checked
  } else {
    console.log('Checkbox is unchecked');
    Layers[6].setVisible(false);
    // Do something when the checkbox is unchecked
  }

}

// Attach the event listener to the checkbox
const JlnJKR_CB = document.getElementById('JlnJKR_CB') as HTMLInputElement;
JlnJKR_CB.addEventListener('change', handleCheckboxJlnJKR);

const handleCheckboxJlnJPS = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  // Add your condition here
  if (checkbox.checked) {
    console.log('Checkbox is checked');
    Layers[7].setVisible(true);
    // Do something when the checkbox is checked
  } else {
    console.log('Checkbox is unchecked');
    Layers[7].setVisible(false);
    // Do something when the checkbox is unchecked
  }

}

// Attach the event listener to the checkbox
const JlnJPS_CB = document.getElementById('JlnJPS_CB') as HTMLInputElement;
JlnJPS_CB.addEventListener('change', handleCheckboxJlnJPS);


const handleOptionOSM = (event: Event) => {

  const option = event.target as HTMLInputElement;
  if (option.checked) {
    const source_osm = new XYZ({
      url: configURL[2],
  })

    setImageryChecked(false);
    setOSMChecked(true);

    Layers[0].setSource(source_osm);

    // updateLayers.push(Layers[0], Layers[1], Layers[2], Layers[3], Layers[4], Layers[5], Layers[6], Layers[7]);
    // mapInit.addLayer(Layers[0]);

  } else {
    console.log("Not check")
  }
  
  // setImageryChecked(false);
  // setOSMChecked(true);
  // console.log("1");

}

const osm_option = document.getElementById('osm_basemap') as HTMLInputElement;
osm_option.addEventListener('click', handleOptionOSM);

const handleOptionImagery = (event: Event) => {
  const option = event.target as HTMLInputElement;
  console.log(option.checked);
  if (option.checked) {
    
    const source_imagery = new XYZ({
      url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  })

    setImageryChecked(true);
    setOSMChecked(false);

    Layers[0].setSource(source_imagery);

    // updateLayers.push(Layers[9], Layers[1], Layers[2], Layers[3], Layers[4], Layers[5], Layers[6], Layers[7]);
    // mapInit.addLayer(Layers[9]);

  } else {
    console.log("Not check")
  }
   
  // setOSMChecked(false);
  // setImageryChecked(true);
  // console.log("2");

}

const imagery_option = document.getElementById('imagery_basemap') as HTMLInputElement;
imagery_option.addEventListener('click', handleOptionImagery);
     
const onchangeOSM = () => {
  const params = new URLSearchParams(window.location.search);
  setImageryChecked(false);
  setOSMChecked(true);
 //  mapInit.setLayers()

 }

 const onchangeImagery = () => {

   setOSMChecked(false);
   setImageryChecked(true);
   
 } 

    }, []); // Empty dependency array ensures the effect runs once after the initial render


    // const params = new URLSearchParams(window.location.search);

    // console.log(params.get('x'));

    // mapInit.
    
    // var showGeneralSearchBar = false;
   
    

    var counterValueGS = 0;

    function onClickGeneralSearch() {
      counterValueGS += 1;
      console.log(counterValueGS);
      const generalSearchBar = document.getElementById('generalSearchBar') as HTMLInputElement;
      const btnSearchGeneralSearch = document.getElementById('searchButtonGeneralSearch') as HTMLInputElement;
      const resultGeneralSearchBarDiv = document.getElementById('resultGeneralSearchBar') as HTMLElement;
      
      if (counterValueGS === 1) {
      generalSearchBar.style.display = "block";
      btnSearchGeneralSearch.style.display = "block";
     } else if(counterValueGS === 2) {
      generalSearchBar.style.display = "none";
      btnSearchGeneralSearch.style.display = "none";
      resultGeneralSearchBarDiv.style.display = "none";
      counterValueGS -= 2;
     }
      // showGeneralSearchBar = true;
      // setDisplaySearchBar = true;
      // if (generalSearchBar.style.display === "none") {
      //     generalSearchBar.style.display = "block";
      // } else { 
      //     generalSearchBar.style.display = "none";
      // }

      
      

    }

  

    var counterValueLayerBtn = 0;
    function onClickLayerButton() {

      counterValueLayerBtn += 1;
      console.log(counterValueLayerBtn);
      const legendBox = document.getElementById('LegendBox') as HTMLDivElement;
    
      
      if (counterValueLayerBtn === 1) {
        legendBox.style.display = "block";
     } else if(counterValueLayerBtn === 2) {
      legendBox.style.display = "none";
      counterValueLayerBtn -= 2;
     }


    }

    var counterValueAS = 0
    function onClickAdvanceSearch() {
      counterValueAS += 1;
      console.log(counterValueAS);
      const advanceSearchBox = document.getElementById('advanceSearchBox') as HTMLInputElement;

      
      if (counterValueAS === 1) {
        advanceSearchBox.style.display = "block";
     } else if(counterValueAS === 2) {
      advanceSearchBox.style.display = "none";
      counterValueAS -= 2;
     }


    }
    
    // if (setDisplaySearchBar === false) {

    //   const generalSearchBar = document.getElementById('generalSearchBar') as HTMLInputElement;
    //   generalSearchBar.style.display = "none";

    // } else {
    //   const generalSearchBar = document.getElementById('generalSearchBar') as HTMLInputElement;
    //   generalSearchBar.style.display = "block";

    // }




    var kampung_data;
    var get_features_data;
    var checker_val = 0;
    var features_data = [];
    const getData = async () => {
      try {
        // Replace the URL with your JSON API endpoint
        // const response = await fetch(configURL[1]+'/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Akampung&outputFormat=application%2Fjson');
        // const response = await fetch(configURL[1]+'/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKRandJPS_Roadline&outputFormat=application%2Fjson')
        const response = await fetch(configURL[1]+'/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Ajkrandjps_centroid&outputFormat=application%2Fjson');
        const data: Item[] = await response.json();
        kampung_data = data;

        for (let i = 0; i < kampung_data["features"].length; i++) {
          delete kampung_data["features"][i]["geometry"];
          delete kampung_data["features"][i]["geometry_name"];
          delete kampung_data["features"][i]["type"];
        }
        console.log(kampung_data["features"]);
        features_data = kampung_data["features"];
        checker_val = checker_val + 1;
     
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getData();


    function generalSearch() {

      // const resultGeneralSearchBarDiv = document.getElementById('resultGeneralSearchBar') as HTMLElement;
      
      const searchKeyword = document.getElementById('generalSearchBar') as HTMLInputElement;

      

      const value = searchKeyword.value;
      var my_json = JSON.stringify(kampung_data)
      var target_filter = value;
      var filter_result = features_data.filter(({ properties: {NAMA_JALAN}}) => NAMA_JALAN.includes(target_filter) ); 
      console.log(filter_result);

      console.log(value);

      displayGeneralSearchResult(value);

      function displayGeneralSearchResult(kw) {
        
  
        const resultGeneralSearchBarDiv = document.getElementById('resultGeneralSearchBar') as HTMLElement;
        var content = "";
        resultGeneralSearchBarDiv.style.display = 'none';

        console.log(kw);

        if (kw !== '') {
        // content = "<option onclick='zoomTo(kw)'>Kampung Asap 2 "+kw+"</option>"
        resultGeneralSearchBarDiv.style.display = 'block';
        
        for (var key in filter_result) {
          if (filter_result.hasOwnProperty(key)) {

            // var result_loc = "http://localhost:3000?x="+filter_result[key].properties.Longitude+"&y="+filter_result[key].properties.Latitude;
            var result_loc = configURL[0]+"?x="+filter_result[key].properties.LONGITUDE+"&y="+filter_result[key].properties.LATITUDE+"&nj="+filter_result[key].properties.NAMA_JALAN;

            // content += "<style>option:hover {background-color: #8cc7f5;}</style><option className='resultItem' id='resultItem' onClick='location.href = `"+result_loc+"`'>"+filter_result[key].properties.Official_n+"</option>";
            content += "<style>option:hover {background-color: #8cc7f5;}</style><option className='resultItem' id='resultItem' onClick='location.href = `"+result_loc+"`'>"+filter_result[key].properties.NAMA_JALAN+"</option>";
          }
        }


        } else {
        content += "";
        }

        // console.log(content);

    
        return resultGeneralSearchBarDiv.innerHTML = content;



      }
      

    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

 
      
      const { value } = event.target;
        // setSearchTerm(value);
        console.log(value);
        console.log(kampung_data);
    

        //We do that to ensure to get a correct JSON
        var my_json = JSON.stringify(kampung_data)
        //We can use {'name': 'Lenovo Thinkpad 41A429ff8'} as criteria too

        
        // console.log(kampung_data);
        console.log(features_data);

        // test filter 
        // var target_filter = "Kg. Asap";
        var target_filter = value;
        // var filter_result = features_data.filter(({ properties: {Official_n}}) => Official_n.includes(target_filter) );   
        var filter_result = features_data.filter(({ properties: {NAMA_JALAN}}) => NAMA_JALAN.includes(target_filter) );   
        console.log(filter_result);

        displayGeneralSearchResult(value);

        

        function displayGeneralSearchResult(kw) {

  
          const resultGeneralSearchBarDiv = document.getElementById('resultGeneralSearchBar') as HTMLElement;
          var content = "";
          resultGeneralSearchBarDiv.style.display = 'none';

          if (kw !== '') {
          // content = "<option onclick='zoomTo(kw)'>Kampung Asap 2 "+kw+"</option>"
          resultGeneralSearchBarDiv.style.display = 'block';
          
          for (var key in filter_result) {
            if (filter_result.hasOwnProperty(key)) {

              // var result_loc = "http://localhost:3000?x="+filter_result[key].properties.Longitude+"&y="+filter_result[key].properties.Latitude;
              var result_loc = configURL[1]+"?x="+filter_result[key].properties.LONGITUDE+"&y="+filter_result[key].properties.LATITUDE;

              // content += "<style>option:hover {background-color: #8cc7f5;}</style><option className='resultItem' id='resultItem' onClick='location.href = `"+result_loc+"`'>"+filter_result[key].properties.Official_n+"</option>";
              content += "<style>option:hover {background-color: #8cc7f5;}</style><option className='resultItem' id='resultItem' onClick='location.href = `"+result_loc+"`'>"+filter_result[key].properties.NAMA_JALAN+"</option>";
            }
          }

          } else {
          content += "";
          }

          // console.log(content);

      
          return resultGeneralSearchBarDiv.innerHTML = content;
 


        }



        // const selectItem = document.getElementById('resultItem') as HTMLElement;
        // selectItem.addEventListener('click', function() {
          
        //   var val = document.getElementById('resultItem');
        //   var item = val.value;
        // } 
        
        // );
    

        
        // var filtered_json = find_in_object(JSON.parse(my_json), {properties:{Official_n:value}});

        // function find_in_object(my_object, my_criteria){

        //   return my_object.filter(function(obj) {
        //     return Object.keys(my_criteria).every(function(c) {
        //       return obj[c] == my_criteria[c];
        //     });
        //   });
        
        // }

        // console.log(filtered_json);
        

      };


      function gotoAdvanceSearch() {
       

        // useEffect(() => {

        //   const mapInit = "";

        //  }, []);

        window.location.href = "/AdvanceSearch";

        console.log("gotoAdvanceSearch");

      }

      const tooltipLayer = (
        <Tooltip id="tooltip">
          <label>Lapisan Data</label>
        </Tooltip>
      );

      const tooltipGeneralSearch = (
        <Tooltip id="tooltipGS">
          <label>Carian Umum</label>
        </Tooltip>
      );

      const tooltipAdvanceSearch = (
        <Tooltip id="tooltipAS">
          <label>Carian Terperinci</label>
        </Tooltip>
      );

      const tooltipUserManual = (
        <Tooltip id="tooltipUM">
          <label>Manual Pengguna</label>
        </Tooltip>
      );


      const logo = require('../img/pahangroad-logo.png');

    // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
    //  const { value } = event.target;   
    //  console.log(value);
    //  setSearchTerm(value);

    // }

    return (
        
    <div id='mapDiv' className='map'>


    <div className='Container containerStyle'>
      <RoadMap className='roadMapStyle'></RoadMap>
      <span className='titleStyle'>Pahang Road Information</span>
      </div>
        
    {/* {isLegendVisible && } */}
    <div className='LegendBox' id='LegendBox'>

    <Form className='LayerForm'>

   <div className="mb-3">
      {/* <Form.Check type='checkbox' checked={!isJlnPersekutuanChecked} onChange={() => setJlnPersekutuanChecked((prev) => !prev)} id='JlnPersekutuanCB' label='Jalan Persekutuan'/> */}
    
    <input type='checkbox' checked={!isJlnPersekutuanChecked} onChange={() => setJlnPersekutuanChecked((prev) => !prev)} id='JlnPersekutuanCB'/>
    &nbsp;<label className='JlnPersekutuanIcon'></label>&nbsp;
    <label>Jalan Persekutuan</label>
    </div>

    <div className="mb-3">
      <input type='checkbox' checked={!isJlnPahangChecked} onChange={() => setJlnPahangChecked((prev) => !prev)} id='JlnPahang_CB'/>
    &nbsp;<label className='JlnPahangIcon'></label>&nbsp;
    <label>Jalan Negeri Pahang</label>
    
    </div>

    <div className="mb-3">
      
      <input type='checkbox' checked={!isSempMukimChecked} onChange={() => setSempMukimChecked((prev) => !prev)} id='SempMukim_CB'/>
    &nbsp;<label className='SempMukimIcon'></label>&nbsp;
    <label>Sempadan Mukim</label>
   
    </div>

    <div className="mb-3">
      <input type='checkbox' checked={!isKampungChecked} onChange={() => setKampungChecked((prev) => !prev)} id='Kampung_CB'/>
    &nbsp;<label className='KampungIcon'></label>&nbsp;
    <label>Kampung</label>
   
    </div>

    <div className="mb-3">
      <input type='checkbox' checked={!isKoordinatJKRChecked} onChange={() => setKoordinatJKRChecked((prev) => !prev)} id='KoordinatJKR_CB'/>
    &nbsp;<label className='KoordinatJalanJKRIcon'></label>&nbsp;
    <label>Koordinat Jalan JKR</label>
    </div>

    <div className="mb-3">
      <input type='checkbox' checked={!isJlnJKRChecked} onChange={() => setJlnJKRChecked((prev) => !prev)} id='JlnJKR_CB'/>
    &nbsp;<label className='JlnKampungJKRIcon'></label>&nbsp;
    <label>Jalan Kampung JKR</label>
    
    </div>

    <div className="mb-3">
        <input type='checkbox' checked={!isJlnJPSChecked} onChange={() => setJlnJPSChecked((prev) => !prev)} id='JlnJPS_CB'/>
      &nbsp;<label className='JlnPertanianJPS'></label>&nbsp;
      <label>Jalan Pertanian JPS</label>
    </div>

    <label><b>Basemap</b></label>

    <div className="mb-2">
        <input type='radio' checked={isOSMChecked} id='osm_basemap'/>
      <span> Openstreetmap</span>
    </div>

    <div className="mb-2">
        <input type='radio' checked={isImageryChecked} id='imagery_basemap'/>
      <span> World Imagery</span>
    </div>

    </Form>

    

    {/* <input type='checkbox' checked={!isChecked} onChange={() => setIsChecked((prev) => !prev)} id='myCB'></input>
    <label>Pahang Boundary</label> */}
    <br></br>

    </div>

    <OverlayTrigger placement="left" overlay={tooltipLayer}> 
    <button className='LayerButton' id='buttonLegend' onClick={onClickLayerButton}>
    <Fragment>
    <LayerIcon className='layerIcon'/>
    </Fragment>
    </button>
    </OverlayTrigger>
   
    <OverlayTrigger placement="right" overlay={tooltipGeneralSearch}> 
   <button className='generalSearch' id='buttonGeneralSearch' onClick={onClickGeneralSearch}>
   <Fragment>
    <Binoculars className='binocularsIcon' />
   </Fragment>
   </button>
   </OverlayTrigger>

   {/* <div className='blankSpace'>Mari kita ke ladang</div> */}
 
  
   {/* <input id='generalSearchBar' type='text' placeholder='Nama jalan' className='generalSearchBar'/>


   <button className='searchButtonGeneralSearch' id='searchButtonGeneralSearch' onClick={generalSearch}>
   <Fragment>
    <SearchOutline className='searchOutlineIcon'/>
   </Fragment>
   </button> */}

   <div className='generalSearchContainer'>
   <table>
    <tr>
      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td>

        <input id='generalSearchBar' type='text' placeholder='Nama jalan' className='generalSearchBar'/>
        
      </td>
      <td>

      <button className='searchButtonGeneralSearch' id='searchButtonGeneralSearch' onClick={generalSearch}>
      <Fragment>
      <SearchOutline className='searchOutlineIcon'/>
      </Fragment>

      </button>
      </td>
    </tr>
   </table>
   </div>



   {/* <button className='advanceSearch' id='buttonAdvanceSearch' onClick={onClickAdvanceSearch}>
   <Fragment>
    <Search/>
   </Fragment>
   </button> */}
   <OverlayTrigger placement="right" overlay={tooltipAdvanceSearch}>
   <button className='advanceSearch' onClick={gotoAdvanceSearch}>
    <Fragment><Search className='searchIcon'/></Fragment></button>
    </OverlayTrigger>
   

   
   <a href={Pdf} rel="noopener noreferrer" target="_blank">
   <OverlayTrigger placement="right" overlay={tooltipUserManual}>
   <button className='manual' id='buttonManual'>
   <Fragment>
    <Book className='bookIcon' />
   </Fragment>
   </button>
   </OverlayTrigger>
   </a>
   
   
   {/* <ul>
        {searchResults.map(item => (
          <h4>{item.name}</h4>
        ))}
    </ul> */}
    <div className='resultGeneralSearchBar' id='resultGeneralSearchBar'></div>

    <div className='advanceSearchBox' id='advanceSearchBox'><label>Advance Search Box</label></div>

   <div id='attributesInfoBox' className='attributesInfoBox'>

   <div id='attributeInfoCloseButton' className='attributeInfoCloseButton' onClick={closeAttributeInfo}>&nbsp;
    <Fragment><CloseCircle className='closeIcon'/></Fragment>
    </div>
    
    {/* <span className='InfoAttribute'>Maklumat Atribut</span> */}
    

    <pre id='formatted-json'></pre>
   </div>

   

    </div>
    );
  };
  
  export default MapComponent;