import React from 'react';
import { Component, useState  } from 'react';
import './LegendLayer.css';

// Start Openlayers imports
import { Map, View} from 'ol'
import { GeoJSON } from 'ol/format'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import VectorTileLayer from 'ol/layer/VectorTile.js';
import VectorTileSource from 'ol/source/VectorTile.js';
import MVT from 'ol/format/MVT.js';
import {applyStyle} from 'ol-mapbox-style';
import {MapboxVectorLayer} from 'ol-mapbox-style';

import { Vector as VectorSource, OSM as OSMSource, TileWMS as TileWMSSource } from 'ol/source'
import { Select as SelectInteraction, defaults as DefaultInteractions } from 'ol/interaction'
import { Attribution, ScaleLine, ZoomSlider, Zoom, Rotate, MousePosition, OverviewMap, defaults as DefaultControls} from 'ol/control'
import {Style,Fill as FillStyle, RegularShape as RegularShapeStyle, Stroke as StrokeStyle, Stroke, Fill, Image, Icon, Text} from 'ol/style'
import { Projection,get as getProjection} from 'ol/proj'


import Overlay from 'ol/Overlay.js';
import XYZ from 'ol/source/XYZ.js';
import {toLonLat} from 'ol/proj.js';
import {toStringHDMS} from 'ol/coordinate.js';
import {configURL} from '../config/config'
import { Circle } from 'ol/geom';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';

// interface myLayerObject {

//     // source: any;
//     // visible: boolean;

// }


// const layerArray:  myLayerObject[] = [];

const layerArray = []

// process.env.REACT_APP_GEOSERVER
const osm_basemap = new TileLayer({
    source: new XYZ({
        url: configURL[2],
    }),
    visible:true
});

// const key = 'pk.eyJ1Ijoic3p5ZGV2IiwiYSI6ImNsdWs0ejRhZjBobTUyaXFwamFyYzhlOGwifQ.49gWYycXmlZN6CdFE8e2dQ';

// const bright_basemap = new VectorTileLayer({declutter: true});
// applyStyle(bright_basemap, 'mapbox://styles/mapbox/bright-v9', {accessToken: key});

const world_imagery = new TileLayer({
  source: new XYZ({
      url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  }),
  visible:true
});

// const satellite_basemap = new VectorTileLayer({
//   declutter: true,
//   source: new VectorTileSource({
//     attributions:
//       '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> ' +
//       '© <a href="https://www.openstreetmap.org/copyright">' +
//       'OpenStreetMap contributors</a>',
//     format: new MVT(),
//     url:
//       'https://{a-d}.tiles.mapbox.com/v4/mapbox.mapbox-streets-v6/' +
//       '{z}/{x}/{y}.vector.pbf?access_token=' +
//       key,
//   }),
//   style: createMapboxStreetsV6Style(Style, FillStyle, StrokeStyle, Icon, Text),
// });

const jalan_persekutuan_source = new VectorSource({
    format: new GeoJSON(),
    url: function (extent) {
      return (
        configURL[3]
      );
    },
  });

  const jalan_pahang_source = new VectorSource({
    format: new GeoJSON(),
    url: function (extent) {
      return (
        configURL[4]
      );
    },
  });

  const sempadan_mukim_source = new VectorSource({
    format: new GeoJSON(),
    url: function (extent) {
      return (
        configURL[5]
      );
    },
  });

  // const iconFeature = new Feature({
  //   geometry: new Point([0, 0])
  // });

  // const iconStyle = new Style({
  //   image: new Icon({
  //     anchor: [0.5, 46],
  //     anchorXUnits: 'fraction',
  //     anchorYUnits: 'pixels',
  //     src: './img/kampung.png',
  //   }),
  // });


  
  // iconFeature.setStyle(iconStyle);



  const kampung_source = new VectorSource({
    format: new GeoJSON(),
    url: function (extent) {
      return (
        configURL[6]
      );
    },
  });

  const jkr_koordinat_source = new VectorSource({
    format: new GeoJSON(),
    url: function (extent) {
      return (
        configURL[7]
      );
    },
  });

  const jkr_jalan_source = new VectorSource({
    format: new GeoJSON(),
    url: function (extent) {
      return (
        configURL[8]
      );
    },
  });

  const jps_jalan_source = new VectorSource({
    format: new GeoJSON(),
    url: function (extent) {
      return (
        configURL[9]
      );
    },
  });


  const jalan_persekutuan_vector = new VectorLayer({
    source: jalan_persekutuan_source,
    visible:true,
    style: new Style({
      stroke: new Stroke({
        color:'#FF8080',
        width: 5
      })

    })
  });

  const jalan_pahang_vector = new VectorLayer({
    source: jalan_pahang_source,
    visible:true,
    style: new Style({
      stroke: new Stroke({
        color:'#3ECBFF',
        width: 5
      })

    })
  });


  const sempadan_mukim_vector = new VectorLayer({
    source: sempadan_mukim_source,
    visible:true,
    style: new Style({
      stroke: new Stroke({
        color:'#000000',
        width: 1
      })

    })
  });

 

  const kampungIcon = new Style({
    image: new Icon({
      // Define the anchor point of the image
      anchor: [0.5, 0.5],
      // Provide the path to your custom image
      src: configURL[10]+'/kampung.png',
      // Set the size of the image
      scale: 0.5,
      // Offset the image to center it on the point
      offset: [0, 0]
    }),
  });

 
  const kampung_vector = new VectorLayer({
    source: kampung_source,
    visible:true,
    style:kampungIcon
  });

  const koordinatJlnJKRIcon = new Style({
    image: new Icon({
      // Define the anchor point of the image
      anchor: [0.5, 0.5],
      // Provide the path to your custom image
      src: configURL[10]+'/koordinat_jalan_jkr.png',
      // Set the size of the image
      scale: 0.5,
      // Offset the image to center it on the point
      offset: [0, 0]
    }),
  });

  const jkr_koordinat_vector = new VectorLayer({
    source: jkr_koordinat_source,
    visible:true,
    style:koordinatJlnJKRIcon
  });


  const jkr_jalan_vector = new VectorLayer({
    source: jkr_jalan_source,
    visible:true,
    style: new Style({
      stroke: new Stroke({
        color:'#edfb56',
        width: 3
      })

    })
  });

  const jps_jalan_vector = new VectorLayer({
    source: jps_jalan_source,
    visible:true,
    style: new Style({
      stroke: new Stroke({
        color:'#02b508',
        width: 3
      })

    })
  });


  layerArray.push(osm_basemap, jalan_persekutuan_vector, jalan_pahang_vector, sempadan_mukim_vector, kampung_vector, jkr_koordinat_vector, jkr_jalan_vector, jps_jalan_vector, world_imagery);

  export const Layers: any[] = layerArray;

  export function getLayer(): any[] {
 
    return Layers;
    

}